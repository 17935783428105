import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";

import {
  isSanityColumnContent,
  isSanityInformationColumns,
  isSanityListBlock,
  isSanityVideo,
} from "@util/types";
import { ColumnContent, Hero, InformationColumns, ListBlock, Video } from "@global";

import Layout from "@shared/layout";
import SEO from "@shared/seo";

interface Props extends PageProps {
  data: Query;
}

export default function AboutPage({ data }: Props) {
  if (data.sanityAboutPage == null) {
    return null;
  }

  const { seo, pageContent, hero } = data.sanityAboutPage;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        {pageContent && (
          <div>
            {pageContent.map(data => {
              if (isSanityColumnContent(data)) {
                return <ColumnContent data={data} key={data._key} />;
              }
              if (isSanityInformationColumns(data)) {
                return <InformationColumns data={data} key={data._key} />;
              }
              if (isSanityVideo(data)) {
                return <Video url={data.url} thumbnail={data.thumbnail} controls key={data._key} />;
              }
              if (isSanityListBlock(data)) {
                return <ListBlock data={data} key={data._key} />;
              }
              return null;
            })}
          </div>
        )}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityAboutPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      pageContent {
        ... on SanityVideo {
          ...sanityVideo
        }
        ... on SanityListBlock {
          ...sanityListBlock
        }
        ... on SanityInformationColumns {
          ...sanityInformationColumns
        }
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
      }
    }
  }
`;
